import { Button, Grid, Link, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { cloneDeep, set } from "lodash"
import React, { useEffect, useRef } from "react"
import { FormattedMessage } from "react-intl"
import { Field, FieldArray } from "redux-form"
import {
    hasTheShipmentDestinationOrOriginCountry,
    isCanadaAlaska,
    isInternationalShipment,
    isUSDomesticOffshoreShipmentWithItemCustoms,
    isUSDomesticShipment,
    isValidCountryForFedexShipment,
} from "../../../actions/validation"
import { goFetch } from "../../../http"
import FormField from "../../form/form-field"
import FormSwitch from "../../form/form-switch"
import { BookHandlingUnits } from "../bookShipment/atoms/BookHandlingUnits"
import { QuickHandlingUnits } from "./quickItems/QuickHandlingUnits"
import { lengthUnit, weightUnit } from "../../util/units"
import { supportedCurrencies } from "../../../misc"
import FormSelectAutocomplete from "../../form/form-select-autocomplete"
import { newFreightDirectHandlingUnit } from "../../../reducers/search"
import { useFlags } from "launchdarkly-react-client-sdk"
import ElectronicExportInformation from "../bookShipment/atoms/ElectronicExportInformation"

const useStyles = makeStyles({
    section: {
        paddingTop: "10px",
    },
    underline: {
        textDecoration: "underline  ",
    },
    bottomContainer: {
        paddingTop: "15px",
    },
    currencyAndMeasurement: {
        paddingBottom: "20px",
    },
    freightClass__helper: {
        textDecoration: "underline",
        color: "#4d148c",
        "&:hover": {
            color: "#ff6200",
            cursor: "pointer",
        },
    },
})

export default function Items({
    formValues = { handlingUnits: [] },
    changeField,
    handleHUPanel,
    isHUOpen,
    itemsIsLoaded,
    favoriteItems,
    doSearch,
    addNewHandlingUnit,
    trackGA,
    editMode,
    gaCategory,
}) {
    const classes = useStyles()
    const {
        isFreightDirect,
        isFreightDirectReturns,
        isFreightBox,
        preferredSystemOfMeasurement,
        handlingUnits = [],
        origin,
        mode,
        destination,
        isAdvancedItems,
        selectedLocation = {},
    } = formValues

    const originCountry = origin?.shippingAddress?.address?.country
    const destinationCountry = destination?.shippingAddress?.address?.country
    const originState = origin?.shippingAddress?.address?.state
    const destinationState = destination?.shippingAddress?.address?.state

    const {
        domesticOffshoreRating,
        eeiCheckbox,
        domesticOffshoreAkRating,
        domesticOffshoreHiRating,
    } = useFlags()
    const isIntl = isInternationalShipment(originCountry, destinationCountry)

    const isUSDomesticOffshoreWithItemCustoms = isUSDomesticOffshoreShipmentWithItemCustoms(
        origin?.shippingAddress?.address?.country,
        destination?.shippingAddress?.address?.country,
        origin?.shippingAddress?.address?.state,
        destination?.shippingAddress?.address?.state
    )

    const isCanadaAlaskaShipment = isCanadaAlaska(
        originState,
        originCountry,
        destinationState,
        destinationCountry
    )
    const isUSDomestic = isUSDomesticShipment(originCountry, destinationCountry)

    const isIntraMexico = originCountry === "MX" && destinationCountry === "MX"

    const isIntraCanada = originCountry === "CA" && destinationCountry === "CA"

    const isCanadaMexico =
        (originCountry === "MX" && destinationCountry === "CA") ||
        (originCountry === "CA" && destinationCountry === "MX")

    const isMXRate = hasTheShipmentDestinationOrOriginCountry(
        originCountry,
        destinationCountry,
        "MX"
    )

    const isPuertoRicoShipment =
        isValidCountryForFedexShipment(
            origin?.shippingAddress?.address?.country
        ) &&
        isValidCountryForFedexShipment(
            destination?.shippingAddress?.address?.country
        ) &&
        origin?.shippingAddress?.address?.state !==
            destination?.shippingAddress?.address?.state &&
        (origin?.shippingAddress?.address?.state === "PR" ||
            destination?.shippingAddress?.address?.state === "PR")

    const isAlaskaShipment =
        isValidCountryForFedexShipment(
            origin?.shippingAddress?.address?.country
        ) &&
        isValidCountryForFedexShipment(
            destination?.shippingAddress?.address?.country
        ) &&
        (origin?.shippingAddress?.address?.state === "AK" ||
            destination?.shippingAddress?.address?.state === "AK") &&
        origin?.shippingAddress?.address?.state !==
            destination?.shippingAddress?.address?.state

    const isHawaiiShipment =
        origin?.shippingAddress?.address?.state === "HI" ||
        destination?.shippingAddress?.address?.state === "HI"

    useEffect(() => {
        if (isIntraMexico) {
            adjustEstimatedVolumenCubicMeter()
        } else {
            adjustEstimatedLinearFeet()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handlingUnits.length])

    useEffect(() => {
        if (!showEEI()) {
            changeField("itns", [])
            changeField("isFedexFileEEI", null)
        }
    }, [
        formValues?.handlingUnits?.some(hu =>
            hu.items?.some(item => item.unitPrice > 2500)
        ),
    ])

    const adjustEstimatedVolumenCubicMeter = (prefix, fieldName, value) => {
        let adjustedFormValues = cloneDeep(formValues)

        set(adjustedFormValues, `${prefix}.${fieldName}`, value)

        const relevantFieldsFilled = (
            adjustedFormValues?.handlingUnits ?? []
        ).every(item => item.length && item.width && item.height && item.count)

        if (relevantFieldsFilled && handlingUnits.length > 0) {
            calculateEstimatedCubicVolumenMeter(
                adjustedFormValues.handlingUnits
            )
        }
    }

    const adjustEstimatedCubicVolumenMeterOnSearchChange = (
        prefix,
        length,
        width,
        height,
        count,
        doNotStack
    ) => {
        let adjustedFormValues = cloneDeep(formValues)

        set(adjustedFormValues, `${prefix}.length`, length)
        set(adjustedFormValues, `${prefix}.width`, width)
        set(adjustedFormValues, `${prefix}.height`, height)
        set(adjustedFormValues, `${prefix}.count`, count)
        set(adjustedFormValues, `${prefix}.doNotStack`, doNotStack)

        const relevantFieldsFilled = adjustedFormValues?.handlingUnits.every(
            item => item.length && item.width && item.height && item.count
        )

        if (relevantFieldsFilled && handlingUnits.length > 0) {
            calculateEstimatedCubicVolumenMeter(
                adjustedFormValues.handlingUnits
            )
        }
    }

    const adjustEstimatedLinearFeet = (prefix, fieldName, value) => {
        let adjustedFormValues = cloneDeep(formValues)

        set(adjustedFormValues, `${prefix}.${fieldName}`, value)
        if (fieldName === "doNotStack") {
            set(adjustedFormValues, `${prefix}.stackable`, !value)
        }

        const relevantFieldsFilled = (
            adjustedFormValues?.handlingUnits ?? []
        ).every(item => item.length && item.width && item.height && item.count)

        if (relevantFieldsFilled && handlingUnits.length > 0) {
            calculateEstimatedLinearFeet(adjustedFormValues.handlingUnits)
        }
    }

    const adjustEstimatedLinearFeetOnSearchChange = (
        prefix,
        length,
        width,
        height,
        count,
        doNotStack
    ) => {
        let adjustedFormValues = cloneDeep(formValues)

        set(adjustedFormValues, `${prefix}.length`, length)
        set(adjustedFormValues, `${prefix}.width`, width)
        set(adjustedFormValues, `${prefix}.height`, height)
        set(adjustedFormValues, `${prefix}.count`, count)
        set(adjustedFormValues, `${prefix}.doNotStack`, doNotStack)

        const relevantFieldsFilled = adjustedFormValues?.handlingUnits.every(
            item => item.length && item.width && item.height && item.count
        )

        if (relevantFieldsFilled && handlingUnits.length > 0) {
            calculateEstimatedLinearFeet(adjustedFormValues.handlingUnits)
        }
    }

    const calculateEstimatedLinearFeet = async handlingUnits => {
        const { preferredSystemOfMeasurement, origin, destination } = formValues

        try {
            const {
                data: {
                    totalLinearFeet,
                    capLoadTotalLinearFeet,
                    volumeTotalLinearFeet,
                    isOverLTLLimit,
                    showWarning,
                },
            } = await goFetch(
                `/quotes/linearfeet`,
                {
                    method: "POST",
                    credentials: "same-origin",
                    headers: {
                        "cache-control": "no-cache",
                    },
                    data: {
                        customCapacityLoadLimit:
                            formValues?.selectedLocation?.shipmentRestrictions
                                ?.capacityShipmentLength,
                        handlingUnits,
                        preferredSystemOfMeasurement,
                        originCountry:
                            origin?.shippingAddress?.address?.country,
                        destinationCountry:
                            destination?.shippingAddress?.address?.country,
                    },
                },
                true
            )
            changeField("totalLinearFeet", totalLinearFeet)
            changeField("capLoadTotalLinearFeet", capLoadTotalLinearFeet)
            changeField("volumeTotalLinearFeet", volumeTotalLinearFeet)
            changeField("userProvidedTotalLinearFeet", false)
            changeField("isOverLTLLimit", isOverLTLLimit)
            changeField("showWarning", showWarning)
        } catch (error) {
            console.error(error)
        }
    }

    const calculateEstimatedCubicVolumenMeter = handlingUnits => {
        const { preferredSystemOfMeasurement } = formValues
        let handlingUnitsCloned = cloneDeep(handlingUnits)
        const inchToMtMultiplier = 0.0254
        const cmToMtdivider = 100
        let totalCubicVolumenMeter = 0
        try {
            handlingUnitsCloned.forEach(item => {
                if (preferredSystemOfMeasurement === "IMPERIAL") {
                    item.length *= inchToMtMultiplier
                    item.width *= inchToMtMultiplier
                    item.height *= inchToMtMultiplier
                } else {
                    item.length /= cmToMtdivider
                    item.width /= cmToMtdivider
                    item.height /= cmToMtdivider
                }

                totalCubicVolumenMeter +=
                    item.length * item.width * item.height * item.count
            })
            changeField(
                "totalCubicVolumenMeter",
                totalCubicVolumenMeter.toFixed(10)
            )
        } catch (error) {
            console.error(error)
        }
    }

    const showEEI = () => {
        return (
            eeiCheckbox &&
            isPuertoRicoShipment &&
            formValues.handlingUnits.some(hu =>
                hu.items.some(item => item.unitPrice > 2500)
            )
        )
    }

    const useIsMount = () => {
        const isMountRef = useRef(true)
        useEffect(() => {
            isMountRef.current = false
        }, [])
        return isMountRef.current
    }

    const isMount = useIsMount()

    useEffect(() => {
        if (!isMount) {
            if (isIntraMexico) {
                changeField("preferredCurrencyCode", "MXN")
                changeField("preferredSystemOfMeasurement", "METRIC")
                changeField("isAdvancedItems", false)
            } else if (isUSDomestic) {
                changeField("preferredCurrencyCode", "USD")
                changeField("preferredSystemOfMeasurement", "IMPERIAL")
            }
        }

        if (
            (isMXRate && !isIntraMexico) ||
            isUsDimWeightEnabled ||
            (domesticOffshoreRating && isPuertoRicoShipment) ||
            (domesticOffshoreAkRating && isAlaskaShipment) ||
            (domesticOffshoreHiRating && isHawaiiShipment)
        ) {
            changeField("isAdvancedItems", true)
        }

        if (
            (domesticOffshoreRating && isPuertoRicoShipment) ||
            (domesticOffshoreAkRating && isAlaskaShipment) ||
            (domesticOffshoreHiRating && isHawaiiShipment)
        ) {
            changeField("preferredCurrencyCode", "USD")
        }
        return () => {
            changeField("isAdvancedItems", false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        originCountry,
        destinationCountry,
        isPuertoRicoShipment,
        isAlaskaShipment,
        isHawaiiShipment,
        selectedLocation,
        formValues.shouldReset,
    ])

    useEffect(() => {
        if (editMode && handlingUnits[0].packageType) {
            changeField("isAdvancedItems", true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editMode])

    useEffect(() => {
        const handlingUnitsCount = handlingUnits.reduce(
            (previousValue, currentValue) =>
                previousValue + parseInt(currentValue.count),
            0
        )

        if (handlingUnitsCount >= 6) {
            changeField("isAdvancedItems", true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handlingUnits])

    const isUsDimWeightEnabled = selectedLocation?.usDimWeight ?? false
    const shouldNotDisplayLineItemsSwitch =
        (isMXRate && !isIntraMexico) ||
        isUsDimWeightEnabled ||
        (domesticOffshoreRating && isPuertoRicoShipment) ||
        (domesticOffshoreAkRating && isAlaskaShipment) ||
        (domesticOffshoreHiRating && isHawaiiShipment)

    return (
        <Grid item container>
            {!isUSDomestic && !isFreightBox && (
                <Grid item container className={classes.currencyAndMeasurement}>
                    <Grid item container xs={6}>
                        <Field
                            component={FormSelectAutocomplete}
                            name="preferredCurrencyCode"
                            label={
                                <FormattedMessage
                                    id="bookShipment.preferredCurrency"
                                    defaultMessage="Preferred Currency"
                                />
                            }
                            options={supportedCurrencies}
                            disabled={
                                isPuertoRicoShipment ||
                                isCanadaAlaskaShipment ||
                                isHawaiiShipment
                            }
                            category={gaCategory}
                        />
                    </Grid>
                    <Grid item container xs={6}>
                        <Field
                            component={FormSelectAutocomplete}
                            name="preferredSystemOfMeasurement"
                            label={
                                <FormattedMessage
                                    id="bookShipment.preferredSystemOfMeasurement"
                                    defaultMessage="Preferred System of Measurement"
                                />
                            }
                            options={[
                                {
                                    value: "IMPERIAL",
                                    label: "lbs/in",
                                },
                                {
                                    value: "METRIC",
                                    label: "kg/cm",
                                },
                            ]}
                            disabled={isPuertoRicoShipment}
                            category={gaCategory}
                        />
                    </Grid>
                </Grid>
            )}
            <Grid item container className={classes.title}>
                <Grid item container xs={4} alignItems="center">
                    <Typography variant="subtitle1">
                        <FormattedMessage
                            id="bookShipment.lineItems__title"
                            defaultMessage="Line Item Information"
                        />
                    </Typography>
                </Grid>
            </Grid>

            <Grid item container justify="space-between">
                {!isFreightDirect &&
                !isFreightDirectReturns &&
                !shouldNotDisplayLineItemsSwitch ? (
                    <Grid item container xs={4} alignItems="center">
                        <Field
                            name={"isAdvancedItems"}
                            component={FormSwitch}
                            label={
                                <FormattedMessage
                                    id="bookShipment.basicInfo__showAdvancedItemEntry"
                                    defaultMessage="Enter full line item details"
                                />
                            }
                            onChange={(e, value) => {
                                trackGA(
                                    gaCategory,
                                    "Advanced Items Switch",
                                    value
                                )

                                if (!value) {
                                    changeField(
                                        "handlingUnits",
                                        (handlingUnits ?? []).map(hu => {
                                            let newHu = newFreightDirectHandlingUnit()
                                            newHu.count = hu?.count ?? 1
                                            newHu.items[0].freightClass =
                                                hu?.items[0].freightClass
                                            newHu.totalWeight = hu?.totalWeight
                                            newHu.height = hu?.height
                                            newHu.width = hu?.width
                                            newHu.length = hu?.length
                                            return newHu
                                        })
                                    )
                                }
                            }}
                        />
                    </Grid>
                ) : (
                    <Grid item container xs={4} alignItems="center" />
                )}
                {!isIntraMexico && !isUsDimWeightEnabled && (
                    <Grid
                        item
                        container
                        xs={4}
                        alignItems="center"
                        justify="flex-end"
                    >
                        <Link
                            underline="hover"
                            rel="noopener noreferrer"
                            href="https://fedex.postclickmarketing.com/LTL-freight-classification-tool"
                            target="_blank"
                        >
                            <Typography
                                variant="caption"
                                className={classes.freightClass__helper}
                            >
                                <FormattedMessage
                                    id="items__freightClassLink"
                                    defaultMessage="What's my freight class?"
                                />
                            </Typography>
                        </Link>
                    </Grid>
                )}
            </Grid>

            {!isFreightDirect && !isFreightDirectReturns && (
                <Grid item container justify="space-between">
                    <Grid item container xs={4} alignItems="center" />
                    {isIntl &&
                        hasTheShipmentDestinationOrOriginCountry(
                            originCountry,
                            destinationCountry,
                            "US"
                        ) && (
                            <Grid
                                item
                                container
                                xs={4}
                                alignItems="center"
                                justify="flex-end"
                            >
                                <a
                                    rel="noopener noreferrer"
                                    href="https://hts.usitc.gov/"
                                    target="_blank"
                                >
                                    <Typography
                                        variant="caption"
                                        className={classes.freightClass__helper}
                                    >
                                        <FormattedMessage
                                            id="items__hscLink__us"
                                            defaultMessage="US - What's my HSC?"
                                        />
                                    </Typography>
                                </a>
                            </Grid>
                        )}
                </Grid>
            )}

            {!isFreightDirect && !isFreightDirectReturns && (
                <Grid item container justify="space-between">
                    <Grid item container xs={4} alignItems="center" />
                    {isIntl &&
                        hasTheShipmentDestinationOrOriginCountry(
                            originCountry,
                            destinationCountry,
                            "CA"
                        ) && (
                            <Grid
                                item
                                container
                                xs={4}
                                alignItems="center"
                                justify="flex-end"
                            >
                                <a
                                    rel="noopener noreferrer"
                                    href="https://www.tariffinder.ca/en/getStarted"
                                    target="_blank"
                                >
                                    <Typography
                                        variant="caption"
                                        className={classes.freightClass__helper}
                                    >
                                        <FormattedMessage
                                            id="items__hscLink__ca"
                                            defaultMessage="CA - What's my HSC?"
                                        />
                                    </Typography>
                                </a>
                            </Grid>
                        )}
                </Grid>
            )}
            {!isAdvancedItems && !isFreightDirect && !isFreightDirectReturns ? (
                <FieldArray
                    name="handlingUnits"
                    component={QuickHandlingUnits}
                    handlePanel={handleHUPanel}
                    isOpen={isHUOpen}
                    isFreightDirect={isFreightDirect}
                    isFreightDirectReturns={isFreightDirectReturns}
                    isFreightBox={isFreightBox}
                    formValues={formValues}
                    changeField={changeField}
                    weightUnit={weightUnit(preferredSystemOfMeasurement)}
                    lengthUnit={lengthUnit(preferredSystemOfMeasurement)}
                    isIntl={isIntl}
                    isUSDomesticOffshoreWithItemCustoms={
                        isUSDomesticOffshoreWithItemCustoms
                    }
                    favoriteItems={favoriteItems}
                    itemsIsLoaded={itemsIsLoaded}
                    doSearch={doSearch}
                    trackGA={trackGA}
                    isIntraMexico={isIntraMexico}
                    isIntraCanada={isIntraCanada}
                    category={gaCategory}
                />
            ) : (
                <FieldArray
                    name="handlingUnits"
                    isQuickRate={true}
                    component={BookHandlingUnits}
                    handlePanel={handleHUPanel}
                    isOpen={isHUOpen}
                    isFreightDirect={isFreightDirect}
                    isFreightDirectReturns={isFreightDirectReturns}
                    isFreightBox={isFreightBox}
                    formValues={formValues}
                    changeField={changeField}
                    weightUnit={weightUnit(preferredSystemOfMeasurement)}
                    lengthUnit={lengthUnit(preferredSystemOfMeasurement)}
                    isIntl={isIntl}
                    isUSDomesticOffshoreWithItemCustoms={
                        isUSDomesticOffshoreWithItemCustoms
                    }
                    favoriteItems={favoriteItems}
                    itemsIsLoaded={itemsIsLoaded}
                    doSearch={doSearch}
                    adjustEstimatedLinearFeet={adjustEstimatedLinearFeet}
                    adjustEstimatedLinearFeetOnSearchChange={
                        adjustEstimatedLinearFeetOnSearchChange
                    }
                    adjustEstimatedVolumenCubicMeter={
                        adjustEstimatedVolumenCubicMeter
                    }
                    adjustEstimatedCubicVolumenMeterOnSearchChange={
                        adjustEstimatedCubicVolumenMeterOnSearchChange
                    }
                    trackGA={trackGA}
                    isIntraMexico={isIntraMexico}
                    isMXRate={isMXRate}
                    editMode={editMode}
                    isUsDimWeightEnabled={isUsDimWeightEnabled}
                    category={gaCategory}
                />
            )}
            <Grid
                item
                container
                className={classes.section}
                justify="space-between"
            >
                <FieldArray
                    name="handlingUnits"
                    component={({ fields }) => (
                        <Button
                            id="quickRateAddLineItem"
                            className={classes.button__add_item}
                            color="primary"
                            type="button"
                            onClick={() => {
                                trackGA(
                                    gaCategory,
                                    "Add Line Item Button Click"
                                )
                                addNewHandlingUnit(fields)
                            }}
                        >
                            +{" "}
                            <FormattedMessage
                                id="bookShipment.addLineItem"
                                defaultMessage="Add Line Item"
                            />
                        </Button>
                    )}
                />
                <Grid item justify="flex-end">
                    {!isFreightDirect &&
                        !isFreightDirectReturns &&
                        isAdvancedItems &&
                        !isIntraMexico && (
                            <Field
                                component={FormField}
                                name="volumeTotalLinearFeet"
                                id="getRates__totalLinearFeet"
                                label={
                                    <FormattedMessage
                                        id="getRates.estLinearFeet"
                                        defaultMessage="Est. Linear Feet"
                                    />
                                }
                                required
                                onChange={() => {
                                    trackGA(
                                        "Quick Rate Flow",
                                        "User Provided Total Linear Feet"
                                    )
                                    changeField(
                                        "userProvidedTotalLinearFeet",
                                        true
                                    )
                                }}
                                category={gaCategory}
                            />
                        )}

                    {isIntraMexico && isAdvancedItems && (
                        <Field
                            component={FormField}
                            name="totalCubicVolumenMeter"
                            id="getRates__totalLinearFeet"
                            label={
                                <FormattedMessage
                                    id="getRates.cubicMeter"
                                    defaultMessage="Est. Cubic Meters"
                                />
                            }
                            readonly={true}
                            category={gaCategory}
                        />
                    )}
                </Grid>
            </Grid>
            {showEEI() ? (
                <ElectronicExportInformation
                    changeField={changeField}
                    formValues={formValues}
                    classes={classes}
                ></ElectronicExportInformation>
            ) : null}
        </Grid>
    )
}
