import React from "react"
import {
    Button,
    Collapse,
    Grid,
    IconButton,
    Typography,
    withStyles,
} from "@material-ui/core"
import { FormattedMessage } from "react-intl"
import { FieldArray } from "redux-form"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ExpandLessIcon from "@material-ui/icons/ExpandLess"
import { EstimatedDutiesTaxesDescription } from "./EstimatedDutiesTaxesDescription"
import SearchIcon from "@material-ui/icons/Search"

const styles = theme => ({
    collapse: {
        width: "100%",
    },
    next: {
        alignItems: "flex-start",
        justifyContent: "end",
    },
})

const EstimatedDutiesTaxes = ({
    category,
    handlingUnits,
    changeField,
    estimatedDutiesValueExpanded,
    estimatedDutiesTaxesDescriptions,
    classes,
    searchHSC,
}) => {
    return (
        <Grid item container>
            <Grid item container justify="space-between">
                <Grid item />
                <Grid
                    item
                    container
                    xs={8}
                    alignItems="center"
                    justify="center"
                >
                    <Typography variant="subtitle1" align="center">
                        <FormattedMessage
                            id="book.estimatedDutiesTaxes__title"
                            defaultMessage="Estimated Duties and Taxes (Optional)"
                        />
                    </Typography>
                </Grid>

                <Grid item>
                    <IconButton
                        onClick={() => {
                            changeField(
                                "estimatedDutiesValueExpanded",
                                !estimatedDutiesValueExpanded
                            )
                        }}
                    >
                        {estimatedDutiesValueExpanded ? (
                            <ExpandLessIcon />
                        ) : (
                            <ExpandMoreIcon />
                        )}
                    </IconButton>
                </Grid>
            </Grid>
            <Grid item container>
                <Collapse
                    in={estimatedDutiesValueExpanded}
                    className={classes.collapse}
                >
                    <Grid item container>
                        <Grid item container xs={4}>
                            <FieldArray
                                name="estimatedDutiesTaxesDescriptions.value"
                                component={EstimatedDutiesTaxesDescription}
                                estimatedDutiesTaxesDescriptions={
                                    estimatedDutiesTaxesDescriptions
                                }
                                handlingUnits={handlingUnits}
                                category={category}
                                isExpanded={estimatedDutiesValueExpanded}
                            />
                        </Grid>
                        <Grid item container xs={8} className={classes.next}>
                            <Button
                                id="estimatedDutiesTaxes_Next"
                                color="primary"
                                variant="outlined"
                            >
                                <SearchIcon
                                // onClick={searchHSC}
                                />
                            </Button>
                        </Grid>
                    </Grid>
                </Collapse>
            </Grid>
        </Grid>
    )
}

export default withStyles(styles)(EstimatedDutiesTaxes)
