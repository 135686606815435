import React, { Fragment, useEffect } from "react"
import formField from "../../../form/form-field"
import { Field } from "redux-form"
import { FormattedMessage } from "react-intl"

export const EstimatedDutiesTaxesDescription = ({
    fields,
    estimatedDutiesTaxesDescriptions,
    category,
    isExpanded,
    handlingUnits,
    data,
    ...props
}) => {
    useEffect(() => {
        fields.removeAll()
        if (isExpanded) {
            initValues()
        }
    }, [isExpanded])
    const initValues = () => {
        handlingUnits.forEach(handlingUnit => {
            handlingUnit.items.forEach(unit => {
                fields.push({
                    item: unit,
                    descriptionValue: unit.description,
                })
            })
        })
    }
    return (
        <Fragment>
            {fields.map((item, index) => (
                <Field
                    category={category}
                    name={`${item}.descriptionValue`}
                    label={
                        <FormattedMessage
                            id="book.estimatedDutiesTaxes__DescriptionOfGood"
                            defaultMessage="Description of Goods"
                        />
                    }
                    component={formField}
                />
            ))}
        </Fragment>
    )
}
