import DestinationSummary from "../summaryCards/DestinationSummary"
import React, { useEffect, useState, useRef, Fragment } from "react"
import {
    Grid,
    Button,
    Typography,
    Modal,
    ClickAwayListener,
} from "@material-ui/core"
import SummaryButtons from "../atoms/SummaryButtons"
import NextButton from "../atoms/NextButton"
import { Field, FieldArray, reduxForm } from "redux-form"
import FormField from "../../../form/form-field"
import { FormattedMessage } from "react-intl"
import { makeStyles } from "@material-ui/styles"
import { supportedCountryCodes } from "../../../../misc"
import { Map } from "../../../util"
import Accessorials from "../atoms/Accessorials"
import { Popper } from "@material-ui/core"
import { SuggestionBox } from "../atoms/SuggestionBox"
import { Element, scroller } from "react-scroll"
import FormSelectAutocomplete from "../../../form/form-select-autocomplete"
import FormZipCode from "../../../form/form-zip-code"
import { resiAddressRequest } from "../../../../actions/quote-request"
import ResidentialAddressDialog from "../../../quotesPage/dialog/resiCheckDialog"
import { union } from "lodash"
import InBondDialog from "../atoms/InBondDialog"
import normalizePhone from "../../../util/normalizePhone"
import { accountTypeError } from "../../../../messages/error-constants"
import SecondLocationModal from "../atoms/SecondLocationModal"
import { useFlags } from "launchdarkly-react-client-sdk"
import {
    addAccessorial,
    removeAccessorial,
} from "../../../../actions/accessorial"
import { connect } from "react-redux"
import { useSnackbarContext } from "../../../../context/providers/snackbarProvider"
import ConfirmActionModal from "../../../common/ConfirmActionModal"
import { closeModal, openModal } from "../../../../actions/modal"
import {
    isMXDomesticShipment,
    isUSDomesticOffshoreShipment,
    isUSDomesticShipment,
    usDomesticOffshoreStates,
} from "../../../../actions/validation"
import { isOffshorePRShipmentFedexValid } from "../../../util/offshoreUtils."
import {
    customerDeliveryAccessorial,
    customerDeliveryAccessorialOffshore,
    customerPickupAccessorialOffshore,
    deprecatedDeliveryAccessorial,
} from "../../../constants/accessorialMessages"
import { bookShipmentFormValidation } from "../validators"
import {
    confirmAccountTypesForMultiCountry,
    isMexicoOffshore,
    isMultiCountryShipment,
    isUnsupportedCountryState,
} from "../../../util/internationalUtils"

const useStyles = makeStyles(theme => ({
    section: {
        paddingTop: "10px",
    },
    accessorialsContainer: {
        paddingLeft: "8px",
    },
    mapContainer: {
        minHeight: "284px",
        maxHeight: "388px",
    },
    formContainer: {
        paddingRight: "8px",
    },
    itemIcon: {
        cursor: "pointer",
        "&:hover": {
            color: theme.palette.secondary.main,
        },
        "&:active": {
            color: theme.palette.secondary.light,
        },
    },
    closeButton: {
        paddingTop: "15px",
    },
    mainInformation: {},
    deliveryInformation: {},
    errorText: { color: "red" },
}))

const UNSUPPORTED_STATES = ["AK", "HI", "PR"]
const STATE_MAP = {
    AK: "Alaska",
    HI: "Hawaii",
    PR: "Puerto Rico",
}
const GA_CATEGORY = "Book Shipment - Destination"

const Destination = ({
    handleComplete,
    handleEdit,
    handleUpdate,
    handleCancelEdit,
    currentStep,
    editMode,
    formValues = {},
    performContactSearch,
    changeField,
    initialValues,
    contactSearchInProgress,
    cities = [],
    deliveryAccessorialsList,
    setIsLoading,
    textInputProps,
    isNotAllowedInBondCountryModal,
    proccedWithOutInbondModal,
    cancelInbondModal,
    invalid,
    invalidInBondCountry,
    isBookingQuickRate,
    isControlledFlow,
    handleSubmit,
    setIsFreightBoxDialogOpen,
    IntercomAPI,
    locations = [],
    originCountry,
    destinationCountry,
    formattedLocations,
    addAccessorial,
    removeAccessorial,
    checkLoadedAKSpecialCases,
    alaskaSpecialHandlingMessageMap,
    openModal,
    closeModal,
    requiredAccessorialList,
}) => {
    const { domesticOffshoreCustomerPud } = useFlags()

    useEffect(() => {
        if (currentStep === 2) {
            IntercomAPI("trackEvent", "book-shipment-destination")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const { openSnackbar } = useSnackbarContext()

    const { canadaMexicoLocationModal, enableGoogleMapRating } = useFlags()

    const {
        destination,
        isFreightDirect,
        isFreightDirectReturns,
        isFreightBox,
        mode,
        shouldDisPlayContact,
        selectedLocation,
        billToLocationSecondAccount = null,
        origin,
    } = formValues
    const classes = useStyles()
    const disabledNextButton =
        invalidInBondCountry || invalid || !formValues?.destination?.contact
    const destinationCompanyNameRef = useRef(null)
    const [showResiModal, setShowResiModal] = useState(false)
    const [locationModalOpen, setLocationModalOpen] = useState(false)

    const [showOverlay, setShowOverlay] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)
    const [shouldShowForm, setShouldShowForm] = useState(!!isBookingQuickRate)
    const [hasIncorrectAccountError, setHasIncorrectAccountError] = useState(
        false
    )
    const [
        errorOriginAndDestinationCountry,
        setErrorOriginAndDestinationCountry,
    ] = useState([])

    const destinationState = destination?.shippingAddress?.address?.state
    const originState = origin?.shippingAddress?.address?.state

    const isAKShipment =
        destination?.shippingAddress?.address?.state === "AK" &&
        destination?.shippingAddress?.address?.country === "US"

    const isThirdPartyOnly =
        !selectedLocation?.isShipperEnabled &&
        selectedLocation?.is3rdPartyEnabled

    const ableToEditDestinationFields = !(
        formValues?.mode === "inbound" &&
        !isThirdPartyOnly &&
        selectedLocation?.shippingAddress?.address?.postalCode !== String(78045)
    )

    const removeOffshoreAccessorials = deliveryAccessorialMainland => {
        const accessorials = [
            deliveryAccessorialMainland,
            customerDeliveryAccessorialOffshore,
            customerPickupAccessorialOffshore,
        ]
        accessorials.forEach(accessorial => {
            if (hasAccessorial(accessorial.value)) {
                removeAccessorial(accessorial.value)
            }
        })
    }
    useEffect(() => {
        const isOffshorePRShipmentFedex = isOffshorePRShipmentFedexValid(
            originCountry,
            originState,
            destinationCountry,
            destinationState
        )

        const isUsDomesticOffshore = isUSDomesticOffshoreShipment(
            originCountry,
            destinationCountry,
            originState,
            destinationState
        )

        if (
            destinationState === "PR" &&
            isOffshorePRShipmentFedex &&
            !domesticOffshoreCustomerPud
        ) {
            addAccessorial(false, deprecatedDeliveryAccessorial)
        } else {
            removeOffshoreAccessorials(deprecatedDeliveryAccessorial)
        }

        if (
            domesticOffshoreCustomerPud &&
            (isUsDomesticOffshore || isOffshorePRShipmentFedex)
        ) {
            if (usDomesticOffshoreStates.includes(destinationState)) {
                addAccessorial(false, customerDeliveryAccessorial)
                addAccessorial(false, customerPickupAccessorialOffshore)
            } else {
                addAccessorial(false, customerDeliveryAccessorialOffshore)
            }
        } else {
            removeOffshoreAccessorials(customerDeliveryAccessorial)
        }
    }, [
        originCountry,
        destinationState,
        destinationCountry,
        originState,
        domesticOffshoreCustomerPud,
    ])

    useEffect(() => {
        if (currentStep === 2) {
            scroller.scrollTo("destinationTitle", {
                duration: 1000,
                smooth: true,
                offset: -140,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editMode])

    useEffect(() => {
        if (
            mode === "inbound" ||
            isBookingQuickRate ||
            !isControlledFlow ||
            shouldDisPlayContact
        ) {
            setShouldShowForm(true)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mode, isBookingQuickRate, isControlledFlow])

    const hasAccessorial = accessorialCode => {
        return deliveryAccessorialsList.some(
            acc => acc?.value === accessorialCode
        )
    }

    const beforeNextStep = async () => {
        await checkAddressValidation()
        setHasIncorrectAccountError(false)
    }

    const checkAddressValidation = async () => {
        const { deliveryAccessorials } = formValues
        let needsResiModal = false

        if (
            !deliveryAccessorials.includes("RESDEL") &&
            !formValues?.isFreightDirect
        ) {
            setIsLoading(true)
            try {
                const addr = formValues?.destination?.shippingAddress?.address

                needsResiModal = await resiAddressRequest(addr)
            } catch (err) {
                //err for resi check
            } finally {
                setIsLoading(false)
            }
        }

        if (needsResiModal) {
            setShowResiModal(true)
        } else {
            if (editMode) {
                handleUpdate()
            } else {
                handleComplete()
            }
        }
    }

    const canadaMexicoCheck = (
        originCountry,
        destinationCountry,
        selectedLocation
    ) => {
        if (
            confirmAccountTypesForMultiCountry(selectedLocation, locations) &&
            canadaMexicoLocationModal
        ) {
            setLocationModalOpen(true)
        } else {
            setErrorOriginAndDestinationCountry([
                originCountry,
                destinationCountry,
            ])
            setHasIncorrectAccountError(true)
            handleComplete(true)
        }
    }

    const setDefaultCountry = origin => {
        if (origin === "MX") {
            return "MX"
        } else {
            return "US"
        }
    }

    const handleInputFocus = (field, e) => {
        if (field === "searchContact") {
            setShowOverlay(true)
            setAnchorEl(e.currentTarget)
        }
    }

    const handleOptionSelect = (option = {}) => {
        if (isFreightBox && option?.address.country !== "US") {
            setIsFreightBoxDialogOpen(true)
            changeField("destination", {
                searchContact: "",
                shippingAddress: {
                    address: {
                        country:
                            initialValues?.destination?.shippingAddress?.address
                                ?.country,
                    },
                },
            })
            setShouldShowForm(true)
            return
        }
        setShowOverlay(false)
        setAnchorEl(null)
        changeField("destination", {
            shippingAddress: {
                address: {
                    country:
                        initialValues?.destination?.shippingAddress?.address
                            ?.country,
                },
            },
        })
        setShouldShowForm(true)
        changeField("destination", {
            shippingAddress: {
                address: {
                    city: option?.address.city,
                    state: option?.address.state,
                    street1: option?.address.street1,
                    street2: option?.address.street2,
                    country: option?.address.country,
                    postalCode: option?.address.postalCode,
                },
                name: option?.name,
            },
            contact: {
                name: option?.contact?.name,
                phone: {
                    phone_number: option?.contact?.phone?.phoneNumber,
                    extension: option?.contact?.phone?.extension,
                },
                email: {
                    email_address: option?.contact?.email?.emailAddress,
                },
            },
            note: option?.note,
        })
        if (option?.deliveryAccessorials?.length) {
            changeField(
                "deliveryAccessorials",
                option?.deliveryAccessorials.map(entry => entry.value) ?? []
            )
        } else {
            changeField("deliveryAccessorials", [])
        }

        if (destinationCompanyNameRef.current) {
            destinationCompanyNameRef.current.focus()
        }
    }

    const handleClickAway = () => {
        if (showOverlay) {
            setShowOverlay(false)
            setAnchorEl(null)
        }
    }

    if (currentStep === 2) {
        return (
            <Grid item container>
                <InBondDialog
                    isDialogOpen={isNotAllowedInBondCountryModal}
                    proccedWithOutInbondModal={proccedWithOutInbondModal}
                    cancelInbondModal={cancelInbondModal}
                    invalidCountry={
                        destination?.shippingAddress?.address?.country
                    }
                />
                <Modal
                    open={locationModalOpen}
                    onClose={() => {
                        setLocationModalOpen(false)
                        if (billToLocationSecondAccount) {
                            changeField("billToLocationSecondAccount", null)
                        }
                    }}
                >
                    <SecondLocationModal
                        formattedLocations={formattedLocations}
                        mode={mode}
                        originCountry={originCountry}
                        beforeNextStep={beforeNextStep}
                        selectedLocation={selectedLocation}
                        changeField={changeField}
                        setLocationModalOpen={setLocationModalOpen}
                        locations={locations}
                        origin={origin}
                        destination={destination}
                    />
                </Modal>

                <Popper
                    open={showOverlay}
                    anchorEl={anchorEl}
                    placement="bottom-start"
                    modifiers={{
                        flip: {
                            enabled: true,
                        },
                        preventOverflow: {
                            enabled: true,
                            boundariesElement: "scrollParent",
                        },
                    }}
                    className={classes.popper}
                >
                    <ClickAwayListener
                        mouseEvent="onMouseDown"
                        touchEvent="onTouchStart"
                        onClickAway={() => handleClickAway()}
                    >
                        <div>
                            <SuggestionBox
                                searchInProgress={contactSearchInProgress}
                                addressesNeeded
                                field={"destination"}
                                term={destination?.searchContact ?? ""}
                                handleAddressSelect={handleOptionSelect}
                                noCities
                            />
                        </div>
                    </ClickAwayListener>
                </Popper>
                <ResidentialAddressDialog
                    isResiDialogOpen={showResiModal}
                    getRateSubmit={() => {
                        let deliveryAccessorials =
                            formValues?.deliveryAccessorials

                        if (typeof deliveryAccessorials === "string") {
                            deliveryAccessorials = deliveryAccessorials.split(
                                ","
                            )
                        }

                        changeField(
                            "deliveryAccessorials",
                            formValues?.selectedResiAccessorials.split(",")
                        )
                        setShowResiModal(false)
                        handleComplete()
                    }}
                    onIgnore={() => {
                        setShowResiModal(false)
                        handleComplete()
                    }}
                    deliveryAccessorials={formValues?.deliveryAccessorials}
                    currentForm={"bookShipment"}
                    resiChips={formValues?.selectedResiAccessorials}
                    resiChipsPrefix={"selectedResiAccessorials"}
                />
                <Grid item container justify="center">
                    <Typography variant="h6">
                        <FormattedMessage
                            id="bookShipment.basicInfo__destination"
                            defaultMessage="Destination Information"
                        />
                    </Typography>
                    <Element name="destinationTitle" />
                </Grid>
                <Grid item container className={classes.section}>
                    <Grid
                        item
                        container
                        xs={7}
                        className={classes.formContainer}
                    >
                        <Grid
                            item
                            container
                            className={classes.mainInformation}
                            alignContent="flex-start"
                        >
                            {(formValues?.mode !== "inbound" ||
                                isThirdPartyOnly ||
                                selectedLocation?.shippingAddress?.address
                                    ?.postalCode == String(78045)) && (
                                <Grid item container alignItems="flex-end">
                                    <Grid
                                        item
                                        container
                                        xs={9}
                                        className={classes.searchContact}
                                    >
                                        <Field
                                            name="destination.searchContact"
                                            label={
                                                <FormattedMessage
                                                    id="generalTerms__mainSearch"
                                                    defaultMessage="Search for a Contact"
                                                />
                                            }
                                            autoFocus
                                            component={FormField}
                                            category={GA_CATEGORY}
                                            onChange={e => {
                                                setShowOverlay(true)
                                                setAnchorEl(e.currentTarget)
                                                performContactSearch(
                                                    e.target.value,
                                                    destination?.shippingAddress
                                                        ?.address?.postalCode
                                                )
                                            }}
                                            onFocus={e =>
                                                handleInputFocus(
                                                    "searchContact",
                                                    e
                                                )
                                            }
                                            InputProps={textInputProps}
                                            disableBrowserAutocomplete
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        container
                                        xs={3}
                                        justify="center"
                                        alignItems="flex-start"
                                    >
                                        <Button
                                            id={"destinationAddNewContact"}
                                            color="primary"
                                            variant="outlined"
                                            onClick={() => {
                                                changeField("destination", {
                                                    shippingAddress: {
                                                        address: {
                                                            country: setDefaultCountry(
                                                                formValues
                                                                    ?.selectedLocation
                                                                    ?.address
                                                                    ?.country
                                                            ),
                                                        },
                                                    },
                                                })
                                                changeField(
                                                    "deliveryAccessorials",
                                                    []
                                                )
                                                setShouldShowForm(true)
                                            }}
                                        >
                                            <FormattedMessage
                                                id="generalTerms__addNew"
                                                defaultMessage="Add New"
                                            />
                                        </Button>
                                    </Grid>
                                </Grid>
                            )}
                            {shouldShowForm && (
                                <Grid item container>
                                    <Grid item container>
                                        <Field
                                            name="destination.shippingAddress.name"
                                            required
                                            label={
                                                <FormattedMessage
                                                    id="generalTerms__companyName"
                                                    defaultMessage="Company Name"
                                                />
                                            }
                                            component={FormField}
                                            category={GA_CATEGORY}
                                            autoFocus={shouldShowForm}
                                            inputRef={destinationCompanyNameRef}
                                        />
                                    </Grid>

                                    <Grid item container xs={8}>
                                        <Field
                                            name="destination.shippingAddress.address.street1"
                                            required
                                            label={
                                                <FormattedMessage
                                                    id="generalTerms__address1"
                                                    defaultMessage="Address 1"
                                                />
                                            }
                                            disabled={
                                                !ableToEditDestinationFields
                                            }
                                            component={FormField}
                                            category={GA_CATEGORY}
                                        />
                                    </Grid>
                                    <Grid item container xs={4}>
                                        <Field
                                            name="destination.shippingAddress.address.street2"
                                            label={
                                                <FormattedMessage
                                                    id="generalTerms__address2"
                                                    defaultMessage="Address 2"
                                                />
                                            }
                                            disabled={
                                                !ableToEditDestinationFields
                                            }
                                            component={FormField}
                                            category={GA_CATEGORY}
                                        />
                                    </Grid>
                                    <Grid item container xs={3}>
                                        <Field
                                            name="destination.shippingAddress.address.postalCode"
                                            label={
                                                <FormattedMessage
                                                    id="generalTerms__zipPostalCode"
                                                    defaultMessage="Zip/Postal Code"
                                                />
                                            }
                                            disabled={
                                                !ableToEditDestinationFields
                                            }
                                            component={FormZipCode}
                                            form="bookShipment"
                                            country={
                                                formValues?.destination
                                                    ?.shippingAddress?.address
                                                    ?.country
                                            }
                                        />
                                    </Grid>
                                    <Grid item container xs={5}>
                                        <Field
                                            name="destination.shippingAddress.address.city"
                                            label={
                                                <FormattedMessage
                                                    id="generalTerms__city__selectOrEnter"
                                                    defaultMessage="City (Select or Enter)"
                                                />
                                            }
                                            component={FormSelectAutocomplete}
                                            options={cities.map(c => ({
                                                value: c,
                                                label: c,
                                            }))}
                                            customValue
                                        />
                                    </Grid>
                                    <Grid item container xs={2}>
                                        <Field
                                            name="destination.shippingAddress.address.state"
                                            label={
                                                <FormattedMessage
                                                    id="generalTerms__state"
                                                    defaultMessage="State"
                                                />
                                            }
                                            disabled={
                                                !ableToEditDestinationFields
                                            }
                                            component={FormField}
                                            category={GA_CATEGORY}
                                            inputProps={{ maxLength: 2 }}
                                        />
                                    </Grid>

                                    <Grid item container xs={2}>
                                        <Field
                                            component={FormSelectAutocomplete}
                                            name="destination.shippingAddress.address.country"
                                            label={
                                                <FormattedMessage
                                                    id="generalTerms__country"
                                                    defaultMessage="Country"
                                                />
                                            }
                                            disabled={
                                                !ableToEditDestinationFields ||
                                                isFreightBox
                                            }
                                            options={supportedCountryCodes}
                                        />
                                    </Grid>
                                    <Grid item container>
                                        <Field
                                            name="destination.contact.name"
                                            label={
                                                <FormattedMessage
                                                    id="generalTerms__contactName"
                                                    defaultMessage="Contact Name"
                                                />
                                            }
                                            component={FormField}
                                            category={GA_CATEGORY}
                                            required={true}
                                        />
                                    </Grid>
                                    <Grid item container xs={4}>
                                        <Field
                                            name="destination.contact.phone.phone_number"
                                            label={
                                                <FormattedMessage
                                                    id="generalTerms__contactPhone"
                                                    defaultMessage="Contact Phone"
                                                />
                                            }
                                            component={FormField}
                                            category={GA_CATEGORY}
                                            normalize={normalizePhone(
                                                formValues?.destination
                                                    ?.shippingAddress?.address
                                                    ?.country
                                            )}
                                            required={true}
                                        />
                                    </Grid>
                                    <Grid item container xs={2}>
                                        <Field
                                            name="destination.contact.phone.extension"
                                            label={
                                                <FormattedMessage
                                                    id="generalTerms__extension"
                                                    defaultMessage="Extension"
                                                />
                                            }
                                            component={FormField}
                                            category={GA_CATEGORY}
                                        />
                                    </Grid>
                                    <Grid item container xs={6}>
                                        <Field
                                            name="destination.contact.email.email_address"
                                            label={
                                                <FormattedMessage
                                                    id="generalTerms__contactEmail"
                                                    defaultMessage="Contact Email"
                                                />
                                            }
                                            component={FormField}
                                            category={GA_CATEGORY}
                                            required={true}
                                        />
                                    </Grid>
                                    {!isFreightDirect &&
                                        !isFreightDirectReturns && (
                                            <Grid
                                                item
                                                container
                                                alignContent="flex-start"
                                            >
                                                <Accessorials
                                                    category={GA_CATEGORY}
                                                    list={
                                                        deliveryAccessorialsList ??
                                                        []
                                                    }
                                                    name={
                                                        "deliveryAccessorials"
                                                    }
                                                    formName="bookShipment"
                                                    formValues={formValues}
                                                    permanentList={
                                                        requiredAccessorialList
                                                    }
                                                />
                                            </Grid>
                                        )}
                                    <Grid
                                        item
                                        container
                                        className={classes.deliveryInformation}
                                    >
                                        <Field
                                            name="destination.note"
                                            label={
                                                <FormattedMessage
                                                    id="orderDetails.delivery__specialInstructions"
                                                    defaultMessage="Special Instructions"
                                                />
                                            }
                                            component={FormField}
                                            category={GA_CATEGORY}
                                            multiline
                                        />
                                    </Grid>
                                    {selectedLocation?.isShippingInstructionEnabled && (
                                        <Grid
                                            item
                                            container
                                            className={
                                                classes.deliveryInformation
                                            }
                                        >
                                            <Field
                                                name="selectedLocation.defaultShippingInstruction"
                                                label={
                                                    <FormattedMessage
                                                        id="locations.shippingInformationDetails__title"
                                                        defaultMessage="Default Shipping Delivery Instructions"
                                                    />
                                                }
                                                component={FormField}
                                                category={GA_CATEGORY}
                                                multiline
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        container
                        xs={5}
                        className={classes.accessorialsContainer}
                    >
                        <Grid item container className={classes.mapContainer}>
                            {enableGoogleMapRating && (
                                <Map
                                    addresses={[
                                        destination?.shippingAddress?.address,
                                    ]}
                                />
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                {hasIncorrectAccountError && (
                    <Typography
                        variant="body2"
                        className={classes.errorText}
                        display="block"
                        gutterBottom
                    >
                        {accountTypeError(
                            errorOriginAndDestinationCountry[0],
                            errorOriginAndDestinationCountry[1]
                        )}
                    </Typography>
                )}

                {editMode ? (
                    <SummaryButtons
                        handleCancelEdit={handleCancelEdit}
                        handleUpdate={() => {
                            if (
                                isMultiCountryShipment(
                                    originCountry,
                                    destinationCountry
                                )
                            ) {
                                canadaMexicoCheck(
                                    originCountry,
                                    destinationCountry,
                                    selectedLocation
                                )
                            } else if (
                                isUnsupportedCountryState(
                                    originCountry,
                                    originState,
                                    destinationCountry,
                                    destinationState
                                ) ||
                                isMexicoOffshore(
                                    originCountry,
                                    originState,
                                    destinationCountry,
                                    destinationState
                                )
                            ) {
                                openSnackbar(
                                    "error",
                                    <FormattedMessage
                                        id="origin_destination_intra__error_message"
                                        defaultMessage="Shipments from {originState} to {destinationState} are not supported on LTL Select"
                                        values={{
                                            originState:
                                                originCountry === "MX" ? (
                                                    <FormattedMessage
                                                        id="generalTerms__country__MX"
                                                        defaultMessage="Mexico"
                                                    />
                                                ) : (
                                                    STATE_MAP[originState]
                                                ),
                                            destinationState:
                                                destinationCountry === "MX" ? (
                                                    <FormattedMessage
                                                        id="generalTerms__country__MX"
                                                        defaultMessage="Mexico"
                                                    />
                                                ) : (
                                                    STATE_MAP[destinationState]
                                                ),
                                        }}
                                    />,
                                    3000
                                )
                            } else if (isAKShipment) {
                                const specialCase = checkLoadedAKSpecialCases(
                                    destination?.shippingAddress?.address
                                )
                                switch (specialCase?.specialHandling) {
                                    case "Charter Service Only *":
                                        openModal(
                                            <ConfirmActionModal
                                                message={
                                                    alaskaSpecialHandlingMessageMap[
                                                        specialCase
                                                            .specialHandling
                                                    ]
                                                }
                                                title={
                                                    <FormattedMessage
                                                        id="origin_specialHandling_charterServiceOnly_title"
                                                        defaultMessage="Charter Service Only"
                                                    />
                                                }
                                                handleConfirm={() =>
                                                    closeModal()
                                                }
                                            />
                                        )
                                        break
                                    case "Not Serviced":
                                        openSnackbar(
                                            "error",
                                            alaskaSpecialHandlingMessageMap[
                                                specialCase.specialHandling
                                            ],
                                            3000
                                        )
                                        break
                                    default:
                                        beforeNextStep()
                                }
                            } else {
                                beforeNextStep()
                            }
                        }}
                        invalid={disabledNextButton}
                    />
                ) : (
                    <NextButton
                        handleComplete={isInvalidNextButton => {
                            if (isInvalidNextButton) {
                                setShouldShowForm(true)
                                handleComplete(isInvalidNextButton)
                            } else if (
                                isMultiCountryShipment(
                                    originCountry,
                                    destinationCountry
                                )
                            ) {
                                canadaMexicoCheck(
                                    originCountry,
                                    destinationCountry,
                                    selectedLocation
                                )
                            } else if (
                                isUnsupportedCountryState(
                                    originCountry,
                                    originState,
                                    destinationCountry,
                                    destinationState
                                ) ||
                                isMexicoOffshore(
                                    originCountry,
                                    originState,
                                    destinationCountry,
                                    destinationState
                                )
                            ) {
                                {
                                    openSnackbar(
                                        "error",
                                        <FormattedMessage
                                            id="origin_destination_intra__error_message"
                                            defaultMessage="Shipments from {originState} to {destinationState} are not supported on LTL Select"
                                            values={{
                                                originState:
                                                    originCountry === "MX" ? (
                                                        <FormattedMessage
                                                            id="generalTerms__country__MX"
                                                            defaultMessage="Mexico"
                                                        />
                                                    ) : (
                                                        STATE_MAP[originState]
                                                    ),
                                                destinationState:
                                                    destinationCountry ===
                                                    "MX" ? (
                                                        <FormattedMessage
                                                            id="generalTerms__country__MX"
                                                            defaultMessage="Mexico"
                                                        />
                                                    ) : (
                                                        STATE_MAP[
                                                            destinationState
                                                        ]
                                                    ),
                                            }}
                                        />,
                                        3000
                                    )
                                }
                            } else if (isAKShipment) {
                                const specialCase = checkLoadedAKSpecialCases(
                                    destination?.shippingAddress?.address
                                )
                                switch (specialCase?.specialHandling) {
                                    case "Charter Service Only *":
                                        openModal(
                                            <ConfirmActionModal
                                                message={
                                                    alaskaSpecialHandlingMessageMap[
                                                        specialCase
                                                            .specialHandling
                                                    ]
                                                }
                                                title={
                                                    <FormattedMessage
                                                        id="origin_specialHandling_charterServiceOnly_title"
                                                        defaultMessage="Charter Service Only"
                                                    />
                                                }
                                                handleConfirm={() =>
                                                    closeModal()
                                                }
                                            />
                                        )
                                        break
                                    case "Not Serviced":
                                        openSnackbar(
                                            "error",
                                            alaskaSpecialHandlingMessageMap[
                                                specialCase.specialHandling
                                            ],
                                            3000
                                        )
                                        break
                                    default:
                                        beforeNextStep()
                                }
                            } else {
                                beforeNextStep()
                            }
                        }}
                        invalid={disabledNextButton}
                        handleSubmit={handleSubmit}
                    />
                )}
            </Grid>
        )
    } else if (currentStep > 2) {
        return (
            <DestinationSummary
                handleEdit={handleEdit}
                formValues={formValues}
                deliveryAccessorialsList={deliveryAccessorialsList}
            />
        )
    }
}

const mapDispatchToProps = (dispatch, props) => ({
    addAccessorial: (isPickup, accessorial) =>
        dispatch(addAccessorial(isPickup, accessorial)),
    removeAccessorial: accessorial => dispatch(removeAccessorial(accessorial)),
    openModal: async node => dispatch(openModal(node)),
    closeModal: async () => dispatch(closeModal()),
})

export default reduxForm({
    // a unique name for the form
    form: "bookShipment",
    validate: bookShipmentFormValidation,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
})(connect(null, mapDispatchToProps)(Destination))
